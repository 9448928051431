import { transportation } from '@/axios'

export default {
  addVehicle (payload) {
    return transportation().post('vehicles/driver', payload)
  },
  getVehiclesMake (payload) {
    return transportation().get('vehicle-makes', payload)
  },
  getVehiclesType (payload) {
    return transportation().get('allowed-vehicle-types', payload)
  }
}
